.perfil{
    border-radius: 50%;
  }
  
  
  .featurette-divider {
      margin: 5rem 0; /* Space out the Bootstrap <hr> more */
    }
    
    /* Thin out the marketing headings */
    .featurette-heading {
      font-weight: 300;
      line-height: 1;
      letter-spacing: -.05rem;
    }
  
    @media (min-width: 40em) {
      /* Bump up size of carousel content */
      .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4;
      }
    
      .featurette-heading {
        font-size: 50px;
      }
    }
    
    @media (min-width: 62em) {
      .featurette-heading {
        margin-top: 7rem;
      }
    }
  
    .bgmenu{
      background-color: rgb(255, 81, 0);
    }
  
    .corcad{
      background-color: rgb(255, 81, 0);
    }
  
   
  
    .textcor{
      color: rgb(255, 81, 0);
    }